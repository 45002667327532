let env = 'prod'
// let baseURL = 'https://be.dms.cornerstone.id/api'
// let baseURL = 'https://be.dms.cornerstone.id/api'
let baseURL = 'https://be.demo-dms.fiture.id/api'
let dummyimage = 'https://via.placeholder.com/250.jpg'
let fileURL = 'https://csn-dms-storage.s3.ap-southeast-1.amazonaws.com/'

let dummypic =
  'https://res.cloudinary.com/belicharcom/image/upload/v1531557474/man_mtyp4x.png'

export { env, baseURL, dummyimage, fileURL, dummypic }
